const INVOICE_TYPES = {
    finalConsumer: 'finalConsumer',
    invoiceA: 'invoiceA',
} as const;

const IVA_CONDITIONS = [
    { name: 'IVA Responsable Inscripto', value: 'ivaResponsableInscripto' },
    { name: 'Monotributista Trabajador Independiente Promovido', value: 'monotributistaIndependientePromovido' },
    { name: 'Monotributista Social', value: 'monotributistaSocial' },
    { name: 'Responsable Monotributo', value: 'responsableMonotributo' },
];

const SHIPPING_TYPES = {
    homeDelivery: 'homeDelivery',
    pickupAtNearestAgency: 'pickupAtNearestAgency',
    pickupAtWarehouse: 'pickupAtWarehouse',
};

const DEFAULT_CENTER = { lat: -37.98, lng: -63.36 };

const EMAIL = 'hola@gamerfactory.com.ar';

const AFIP_QR_URL = 'http://qr.afip.gob.ar/?qr=WKqENP-YPN1Un429bAecnw,,';
const AFIP_QR_IMAGE_SRC = 'http://www.afip.gob.ar/images/f960/DATAWEB.jpg';

const SHIPPING_ERROR_MESSAGE = 'Hubo un error al intentar obtener las agencias de envío. Por favor, intentá nuevamente.';

const TWENTY_FOUR_HOURS = 24;

const MILLISECONDS_PER_HOUR = 36e5;

export { AFIP_QR_IMAGE_SRC, AFIP_QR_URL, DEFAULT_CENTER, EMAIL, INVOICE_TYPES, IVA_CONDITIONS, MILLISECONDS_PER_HOUR, SHIPPING_ERROR_MESSAGE, SHIPPING_TYPES, TWENTY_FOUR_HOURS };

export const LABEL_IS_SUBSIDIZED_MP = 'ahora';
