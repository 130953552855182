function setLoggedIn(loggedIn) {
    localStorage.setItem('loggedIn', loggedIn);
}

function clearStorage() {
    localStorage.clear();
}

function clearSessionStorage() {
    sessionStorage.clear();
}

function waitGoogleLogin() {
    localStorage.setItem('redirectAfterGoogleLogin', window.location.pathname);
}

function clearWaitGoogleLogin() {
    localStorage.removeItem('redirectAfterGoogleLogin');
}

const storageHelper = {
    setLoggedIn,
    waitGoogleLogin,
    clearWaitGoogleLogin,
    clearStorage,
    clearSessionStorage,
};

export default storageHelper;
