import { initialCart } from '../Checkout/constants';
import { USER_ACTIONS, initialUser } from './constants';
import { UserActions, UserState } from './types';

export const userInitialState: UserState = initialUser;

export const userReducer = (state: UserState = userInitialState, action: UserActions): UserState => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.user,
                    cart: state.user.cart === userInitialState.user.cart ? action.user.cart : state.user.cart,
                },
            };
        case USER_ACTIONS.LOGOUT:
            return {
                ...state,
                user: initialUser.user,
            };
        case USER_ACTIONS.UPDATE_CART:
            return {
                ...state,
                user: {
                    ...state.user,
                    cart: {
                        ...state.user.cart,
                        ...action.cart,
                    },
                },
            };
        case USER_ACTIONS.UPDATE_COUPON:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...(state.user.cart
                        ? {
                              cart: {
                                  ...state.user.cart,
                                  appliedCoupon: action.appliedCoupon,
                              },
                          }
                        : {}),
                },
            };
        case USER_ACTIONS.DELETE_CART:
            return {
                ...state,
                user: {
                    ...state.user,
                    cart: initialCart,
                },
            };
        case USER_ACTIONS.UPDATE_PROFILE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profileImageURL: action.profileImageURL,
                },
            };
        default:
            return state;
    }
};
