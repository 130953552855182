import { Box } from '@material-ui/core';
import { useContext } from 'react';

import { UserContext } from '../../../contexts/User';
import ShippingCost from './ShippingCost';
import Total from './Total';
import TotalWithCoupon from './TotalWithCoupon';

interface Props {
    showShippingCosts: boolean;
    loadingShippingPrice: boolean;
    loadingTotalPrice: boolean;
}

const TotalPrice = ({ showShippingCosts, loadingShippingPrice, loadingTotalPrice }: Props) => {
    const [state] = useContext(UserContext);

    return (
        <Box mt={1} mb={2}>
            {state.user.cart?.appliedCoupon ? (
                <TotalWithCoupon showShippingCosts={showShippingCosts} loadingShippingPrice={loadingShippingPrice} loadingTotalPrice={loadingTotalPrice} />
            ) : (
                <>
                    <ShippingCost showShippingCosts={showShippingCosts} loadingShippingPrice={loadingShippingPrice} />
                    <Total loadingTotalPrice={loadingTotalPrice} />
                </>
            )}
        </Box>
    );
};

export default TotalPrice;
