import { initialCart } from '../Checkout/constants';

export const USER_ACTIONS = {
    LOGOUT: 'LOGOUT',
    SET_USER: 'SET_USER',
    DELETE_CART: 'DELETE_CART',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    UPDATE_CART: 'UPDATE_CART',
    UPDATE_COUPON: 'UPDATE_COUPON',
} as const;

export const initialShippingData = {
    address: '',
    agencyId: null,
    codigoPostal: null,
    dni: '',
    firstName: '',
    floor: '',
    lastName: '',
    locality: null,
    phone: '',
    price: null,
    province: null,
    shippingType: '',
    useTelegramForTracking: false,
};

export const initialUser = {
    user: {
        email: null,
        role: null,
        cart: initialCart,
        shippingData: initialShippingData,
    },
};
