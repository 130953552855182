import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import cartHelper from '../../../helpers/cartHelper';
import { BlackSmallCircularProgress } from '../BlackSmallCircularProgress';
import { useTotalStyles } from './useStyles';

interface Props {
    loadingTotalPrice: boolean;
}
const Total = ({ loadingTotalPrice }: Props) => {
    const classes = useTotalStyles();
    const { totalPriceToShow, getMPInterestForCart } = useCheckout();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'interest-Popover',
    });
    return (
        <>
            <Grid container spacing={3} style={{ marginTop: '8px' }}>
                {!loadingTotalPrice && getMPInterestForCart() > 0 && (
                    <>
                        <Grid item xs={4} className={classes.containerInterest}>
                            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                Interés
                            </Typography>
                            <IconButton aria-label="más información" color="inherit" className={classes.iconButton} {...bindHover(popupState)}>
                                <InfoIcon className={classes.iconMoreInformation} />
                            </IconButton>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                disableRestoreFocus
                            >
                                <Box className={classes.containerMoreInfo}>
                                    <Typography variant="subtitle2" className={classes.infoInterest}>
                                        Algunas tarjetas pueden no estar adheridas al programa Cuota Simple y aplicar recargo según el emisor.
                                    </Typography>
                                </Box>
                            </Popover>
                        </Grid>
                        <Grid item xs={8}>
                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                    {cartHelper.formatPrice(getMPInterestForCart())}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
                <Grid item xs={4}>
                    <Typography variant="h6">Total</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end">
                        {loadingTotalPrice ? <BlackSmallCircularProgress /> : <Typography variant="h3">{cartHelper.formatPrice(totalPriceToShow ?? 0)}</Typography>}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Total;
