import { INVOICE_TYPES } from '../../common/constants';

export const initialBuyerData = {
    businessAddress: '',
    businessName: '',
    cuit: '',
    dni: '',
    firstName: '',
    floor: '',
    invoiceType: INVOICE_TYPES.finalConsumer,
    ivaCondition: '',
    lastName: '',
    locality: undefined,
    phone: '',
    province: undefined,
};

export const initialCart = {
    id: null,
    totalPrice: {
        list: 0,
        special: 0,
    },
    items: [],
    isSubsidizedShippingPrice: false,
};
