import { Box, Button, Checkbox, FormControl, FormControlLabel, InputAdornment, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';

import API from '../../../../api/API';
import { useUser } from '../../../../contexts/User';
import { USER_ACTIONS, initialShippingData } from '../../../../contexts/User/constants';
import storageHelper from '../../../../helpers/storageHelper';
import useSnackbarGD from '../../../../hooks/useSnackbar';
import LoadingButton from '../../../LoadingButton';
import ForgotPasswordModal from '../../../authentication/ForgotPasswordModal';
import GoogleLoginButton from '../../../authentication/GoogleLoginButton';
import { getGoogleAuthUrl } from '../../../authentication/utils';
import CustomDivider from '../../../components/CustomDivider';

const useStyles = makeStyles(() => ({
    login: {
        margin: 0,
        maxWidth: 302,
    },
    rememberMeLabel: {
        marginLeft: 0,
    },
    forgotPassword: {
        fontWeight: 'lighter',
    },
    checkboxFormControl: {
        marginLeft: 0,
    },
    uncheckedCheckbox: {
        content: 'url(/images/uncheckedCheckbox.svg)',
    },
    checkedCheckbox: {
        content: 'url(/images/checkedCheckbox.svg)',
    },
    emailInput: {
        marginTop: '31px',
    },
}));

const CheckoutLogin = ({ goToNextStep, openRegister }) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {
        state: {
            user: { cart },
        },
        cart: { updateCart },
        dispatch,
    } = useUser();
    const [loading, setLoading] = useState(false);
    const { showSnackbarMessage } = useSnackbarGD();

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickRememberMe = () => setRememberMe(!rememberMe);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        API.auth
            .login({ email, password, rememberMe })
            .then((response) => {
                const { token } = response.data;
                const decodedToken = jwtDecode(token);
                const user = {
                    email,
                    role: decodedToken.role,
                    profileImageURL: decodedToken.profileImageURL,
                    shippingData: decodedToken.shippingData || initialShippingData,
                };
                dispatch({ type: USER_ACTIONS.SET_USER, user });
                updateCart(cart);

                setEmail('');
                setPassword('');
                goToNextStep();
            })
            .catch((error) => {
                if (error.response?.status === 401) showSnackbarMessage('Tu usuario y/o contraseña no parecen ser válidos. Por favor revisalos e intentá nuevamente.', 'error');
                else showSnackbarMessage('Hubo un problema intentando iniciar sesión. Por favor intentá nuevamente en unos minutos', 'warning');
            })
            .finally(() => setLoading(false));
    };

    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangePassword = (event) => setPassword(event.target.value);

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        storageHelper.waitGoogleLogin();
        window.location.href = getGoogleAuthUrl(cart);
    };

    const handleForgotPasswordClick = () => {
        setForgotPasswordModalOpen(true);
    };

    return (
        <div>
            <Box mt={1} display="flex">
                <Typography variant="h4">Iniciar sesión</Typography>
            </Box>
            <Box mt={1} display="flex">
                <Typography variant="subtitle1">Ingresa a tu cuenta para poder continuar con la compra.</Typography>
            </Box>
            <Box display="flex">
                <Typography variant="subtitle1">
                    ¿No tenés cuenta en Gamer Factory?{' '}
                    <Button variant="text" onClick={openRegister}>
                        CREAR CUENTA
                    </Button>
                </Typography>
            </Box>
            <Box textAlign="center">
                <Box className={classes.login}>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <TextField
                                type="email"
                                placeholder="Email"
                                value={email}
                                InputProps={{
                                    className: classes.emailInput,
                                }}
                                onChange={handleChangeEmail}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete="current-password"
                                placeholder="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handleChangePassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box display="flex" justifyContent="space-between">
                                <Box mt={1} display="flex" justifyContent="flex-start">
                                    <FormControlLabel
                                        control={<Checkbox name="rememberMe" onChange={handleClickRememberMe} className={rememberMe ? classes.checkedCheckbox : classes.uncheckedCheckbox} />}
                                        label={
                                            <Typography variant="subtitle2" className={classes.rememberMeLabel}>
                                                Recordarme
                                            </Typography>
                                        }
                                        className={classes.checkboxFormControl}
                                    />
                                </Box>

                                <Box mt={1} display="flex" alignItems="flex-end" justifyContent="flex-end">
                                    <Button variant="text" className={classes.forgotPassword} onClick={handleForgotPasswordClick}>
                                        ¿Olvidaste la contraseña?
                                    </Button>
                                    <ForgotPasswordModal open={forgotPasswordModalOpen} setOpen={setForgotPasswordModalOpen} />
                                </Box>
                            </Box>
                        </FormControl>

                        <Box mt={4} mb={2}>
                            <FormControl fullWidth>
                                <LoadingButton loading={loading} variant="contained" type="submit">
                                    Iniciar sesión
                                </LoadingButton>
                            </FormControl>
                        </Box>
                    </form>

                    <CustomDivider />
                    <GoogleLoginButton onClick={handleGoogleLogin} />
                </Box>
            </Box>
        </div>
    );
};

export default CheckoutLogin;
