import { useState } from 'react';

import API from '../api/API';
import { initialShippingData } from '../contexts/User/constants';
import { getTextInsideParentheses } from '../resources/completeOrder/components/steps/Shipping/utils';
import cartHelper from './cartHelper';
import { normalizeString } from './utils';

const setAgenciesAndDestinationsInSessionStorage = async () => {
    try {
        const expires = new Date();
        expires.setDate(expires.getDate() + 1);
        const agencies = await API.shipping
            .agencies()
            .then((response) => response.data)
            .catch((error) => console.error(error));
        const destinations = await API.shipping
            .destinations()
            .then((response) => response.data)
            .catch((error) => console.error(error));

        sessionStorage.setItem('expiresAt', JSON.stringify(expires));
        sessionStorage.setItem('agencies', JSON.stringify(agencies));
        sessionStorage.setItem('destinations', JSON.stringify(destinations));
    } catch (error) {
        console.error(error);
    }
};

const assureAgenciesAndDestinationsAreUpToDate = async () => {
    const expiresAtString = sessionStorage.getItem('expiresAt');
    const agenciesString = sessionStorage.getItem('agencies');
    const destinationsString = sessionStorage.getItem('destinations');
    if (!expiresAtString || !agenciesString || !destinationsString) {
        await setAgenciesAndDestinationsInSessionStorage();
    }
    const currentDate = new Date();
    const expirationDate = JSON.parse(sessionStorage.getItem('expiresAt') || {});
    if (Date.parse(currentDate) >= Date.parse(expirationDate)) {
        await setAgenciesAndDestinationsInSessionStorage();
    }
};

const getDestinations = async () => {
    await assureAgenciesAndDestinationsAreUpToDate();
    return JSON.parse(sessionStorage.getItem('destinations'));
};
const getAgencies = async () => {
    await assureAgenciesAndDestinationsAreUpToDate();
    return JSON.parse(sessionStorage.getItem('agencies'));
};

const getAvailableLocalities = async (province) => {
    const destinations = await getDestinations();
    return destinations
        .filter((i) => i.Provincia?.toUpperCase() === province?.id?.toUpperCase())
        .sort((a, b) => {
            if (a.Descripcion > b.Descripcion) return 1;
            if (b.Descripcion > a.Descripcion) return -1;
            return 0;
        })
        .map((i) => ({
            id: i.Descripcion,
            codigoPostal: i.CodigoPostal,
            name: i.Descripcion,
        }));
};
const getAvailableAgencies = async (province) => {
    const agencies = await getAgencies();
    return agencies
        .filter((i) => i.Provincia?.toUpperCase() === province?.id?.toUpperCase())
        .sort((a, b) => {
            if (a.Localidad > b.Localidad) return 1;
            if (b.Localidad > a.Localidad) return -1;
            return 0;
        })
        .map((i) => ({
            name: i.Localidad,
            id: i.IdAgencia,
            lat: i.Latitud,
            long: i.Longitud,
            socialReason: i.RazonSocial,
            address: i.Direccion,
            codigoPostal: i.IdCodigoPostal,
        }));
};

const getUniqueLocalities = async (availableAgencies) => availableAgencies.filter((elem, index) => availableAgencies.findIndex((agency) => agency.name === elem.name) === index);

const getShippingDataForProvinceChange = ({ newProvince, shippingData }) => ({
    ...shippingData,
    province: newProvince,
    locality: null,
    codigoPostal: null,
    price: null,
    address: '',
    floor: '',
});

const formatName = (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

const formatShippingDataForUser = (shippingData) => ({
    address: shippingData?.address,
    agencyId: shippingData?.agencyId,
    codigoPostal: shippingData?.codigoPostal,
    dni: shippingData?.dni,
    firstName: shippingData?.firstName,
    lastName: shippingData?.lastName,
    floor: shippingData?.floor,
    locality: shippingData?.locality || null,
    phone: shippingData?.phone,
    price: shippingData?.price || null,
    province: shippingData?.province || null,
    shippingType: shippingData?.shippingType || '',
    useTelegramForTracking: false,
});

const formatShippingDataForQuoting = ({ shippingData, buyerData }) => ({
    address: shippingData?.address,
    agencyId: shippingData?.agencyId,
    codigoPostal: shippingData?.codigoPostal || (shippingData?.locality ? getTextInsideParentheses(shippingData?.locality) : null),
    dni: buyerData?.dni,
    firstName: buyerData?.firstName,
    lastName: buyerData?.lastName,
    floor: shippingData?.floor,
    locality: formatLocality(shippingData?.locality),
    phone: buyerData?.phone,
    price: shippingData?.price || null,
    province: formatProvince(shippingData?.province),
    shippingType: shippingData?.shippingType || '',
    useTelegramForTracking: false,
});

const formatGenericLocation = (item, additionalProps = {}) => {
    if (!item || typeof item === 'object') return item ?? null;
    return {
        name: item,
        id: item,
        ...additionalProps,
    };
};

const formatProvince = (province) => formatGenericLocation(province);

const formatLocality = (locality) =>
    formatGenericLocation(locality, {
        codigoPostal: locality ? getTextInsideParentheses(locality) : null,
    });

const matchProvinceFromAvailableList = (province) =>
    cartHelper.getProvinces().find((p) => normalizeString(p.id.toString()) === normalizeString(typeof province === 'string' ? province : province.id.toString()));

const formatUserPredefinedLocalityToObject = async (data) => {
    const locality = data?.name || data;
    if (!locality) return null;
    const destinations = await getDestinations();
    const userLocality = destinations.find((l) => l.Descripcion.toUpperCase() === locality?.toUpperCase());
    const userLocalityAsObject = {
        name: userLocality?.Descripcion,
        id: userLocality?.Descripcion,
        codigoPostal: userLocality?.CodigoPostal,
    };
    return userLocalityAsObject;
};

const getShippingDataFromUser = async (userShippingData) => {
    if (!userShippingData) return null;

    const province = userShippingData?.province ? matchProvinceFromAvailableList(userShippingData.province) : null;

    const { address, floor, phone, shippingType, useTelegramForTracking } = userShippingData;

    const shippingDataFromUserSettings = {
        address: address || '',
        codigoPostal: null,
        floor: floor || '',
        locality: null,
        phone: phone || '',
        price: null,
        province,
        shippingType: shippingType || '',
        useTelegramForTracking: useTelegramForTracking || false,
    };

    return shippingDataFromUserSettings;
};

const usePersistedData = (key, initialValue = initialShippingData) => {
    const [persistedData, setPersistedData] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            if (item) return JSON.parse(item);
            const province = matchProvinceFromAvailableList(initialValue?.province);
            const locality = initialValue?.locality?.name || initialValue?.locality;
            return {
                ...initialValue,
                province,
                locality,
            };
        } catch (error) {
            return initialValue;
        }
    });

    const setData = (value) => {
        try {
            setPersistedData(value);
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    };

    return [persistedData, setData];
};

const shippingDataHelper = {
    initialShippingData,
    formatName,
    formatShippingDataForUser,
    formatShippingDataForQuoting,
    formatUserPredefinedLocalityToObject,
    matchProvinceFromAvailableList,
    getAvailableAgencies,
    getAvailableLocalities,
    getShippingDataForProvinceChange,
    getShippingDataFromUser,
    getUniqueLocalities,
    usePersistedData,
};

export default shippingDataHelper;
