import { AppBar, Box, Container, Hidden, IconButton, List, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import GDCart from './GDCart';
import GDSearcher from './GDSearcher';
import API from './api/API';
import { UserContext } from './contexts/User';
import { USER_ACTIONS } from './contexts/User/constants';
import emptyUser from './emptyUser.svg';
import cartHelper from './helpers/cartHelper';
import logo from './logo.svg';
import logoMobile from './logoMobile.svg';
import ForgotPasswordModal from './resources/authentication/ForgotPasswordModal';
import LoginModal from './resources/authentication/LoginModal';
import RegisterModal from './resources/authentication/RegisterModal';

const useStyles = makeStyles((theme) => ({
    appbar: {
        background: '#F8E837',
        color: '#1C1C1C',
        borderBottom: '2.81px solid #1C1C1C',
        whiteSpace: 'nowrap',
    },
    toolbar: { justifyContent: 'space-between' },
    appbarContainer: {
        padding: 0,
    },
    menuItem: {
        padding: '0 20px',
        '&:not(:last-child)': {
            borderRight: '1px solid #1C1C1C',
        },
        '&:hover': {
            textStroke: '0.5px #1c1c1c',
            '-webkit-text-stroke': '0.5px #1c1c1c',
            fontWeight: 'normal',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'left',
            paddingLeft: '20px',
        },
    },
    subMenuItem: {
        margin: '0 15px',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 15,
        paddingBottom: 15,
        borderBottom: '1px solid #DBDBD5',
        '&:last-child': {
            borderBottom: 0,
        },
        '&:hover': {
            textStroke: '0.5px #1c1c1c',
            '-webkit-text-stroke': '0.5px #1c1c1c',
            fontWeight: 'normal',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    logo: {
        height: '34px',
    },
    userImage: {
        borderRadius: '50%',
        width: 25,
        height: 25,
        objectFit: 'cover',
    },
}));

const LOCATIONS_REQUIRING_H1 = ['/', '/productos'];

const DesktopMenuLinks = () => {
    const classes = useStyles();
    const [buildersAnchorEl, setBuildersAnchorEl] = useState(null);
    const isBuildersMenuOpen = Boolean(buildersAnchorEl);

    const handleBuildersMenuOpen = (event) => {
        setBuildersAnchorEl(event.currentTarget);
    };

    const handleBuildersMenuClose = () => {
        setBuildersAnchorEl(null);
    };

    const buildersMenuId = 'builders-menu';
    const renderBuildersMenu = (
        <Menu
            anchorEl={buildersAnchorEl}
            getContentAnchorEl={null}
            disableScrollLock
            elevation={0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            id={buildersMenuId}
            open={isBuildersMenuOpen}
            onClose={handleBuildersMenuClose}
        >
            <MenuItem key="easybuilder" component={Link} to="/easybuilder" className={classes.subMenuItem} onClick={handleBuildersMenuClose}>
                Easy Builder
            </MenuItem>
            <MenuItem key="probuilder" component={Link} to="/probuilder" className={classes.subMenuItem} onClick={handleBuildersMenuClose}>
                Pro Builder
            </MenuItem>
        </Menu>
    );

    return (
        <List component="nav" className={classes.sectionDesktop} aria-labelledby="main navigation">
            <MenuItem key="Armá tu PC" className={classes.menuItem} onClick={handleBuildersMenuOpen}>
                Armá tu PC
                <ArrowDropDownIcon />
            </MenuItem>
            {renderBuildersMenu}
            <MenuItem key="Productos" className={classes.menuItem} component={Link} to="/categorias">
                Productos
            </MenuItem>
            <MenuItem key="Builds" className={classes.menuItem} component={Link} to="/productos/pcs-escritorio-gaming">
                Builds
            </MenuItem>
            <MenuItem key="Ofertas" className={classes.menuItem} component={Link} to="/ofertas">
                Ofertas 🔥
            </MenuItem>
            {/* <MenuItem
                key="Comunidad GF"
                className={classes.menuItem}
                component={Link}
                to={{ pathname: process.env.REACT_APP_COMUNIDAD_URL }}
                target="_blank"
            >
                Comunidad GF
            </MenuItem> */}
        </List>
    );
};

const AccountMenu = ({ accountAnchorEl, handleAccountMenuClose, handleAccountMenuOpen, userImage }) => {
    const classes = useStyles();
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [registerModalOpen, setRegisterModalOpen] = useState(false);
    const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

    const isAccountMenuOpen = Boolean(accountAnchorEl);
    const [state, dispatch] = useContext(UserContext);

    const handleLoginClick = () => {
        handleAccountMenuClose();
        setLoginModalOpen(true);
    };

    const handleRegisterClick = () => {
        handleAccountMenuClose();
        setRegisterModalOpen(true);
    };

    const handleLogout = () => {
        API.auth.logout();
        dispatch({ type: USER_ACTIONS.LOGOUT });
        handleAccountMenuClose();
    };

    const handleRegisterModalOpen = () => {
        setRegisterModalOpen(true);
        setLoginModalOpen(false);
    };

    const handleForgotPasswordModalOpen = () => {
        setForgotPasswordModalOpen(true);
        setLoginModalOpen(false);
    };

    const handleLoginModalOpen = () => {
        setLoginModalOpen(true);
        setRegisterModalOpen(false);
    };

    const accountMenuId = 'account-menu';
    const accountMenu = (
        <Menu
            anchorEl={accountAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={accountMenuId}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isAccountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            {!state.user?.role
                ? [
                      <MenuItem key="login" className={classes.subMenuItem} onClick={handleLoginClick}>
                          Iniciar Sesión
                      </MenuItem>,
                      <MenuItem key="register" className={classes.subMenuItem} onClick={handleRegisterClick}>
                          Registrarse
                      </MenuItem>,
                  ]
                : [
                      <MenuItem key="account" component={Link} className={classes.subMenuItem} to="/account" onClick={handleAccountMenuClose}>
                          Mi cuenta
                      </MenuItem>,
                      <MenuItem key="logout" component={Link} className={classes.subMenuItem} to="/" onClick={handleLogout}>
                          Cerrar Sesión
                      </MenuItem>,
                  ]}
        </Menu>
    );

    return (
        <Box pr={1}>
            {state?.user?.email ? (
                <Tooltip title={state.user.email} placement="top">
                    <IconButton
                        sx={{ display: 'flex' }}
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={accountMenuId}
                        aria-haspopup="true"
                        onClick={handleAccountMenuOpen}
                        color="inherit"
                    >
                        <img src={userImage ?? emptyUser} alt="user" className={classes.userImage} />
                    </IconButton>
                </Tooltip>
            ) : (
                <IconButton
                    sx={{ display: 'flex' }}
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={accountMenuId}
                    aria-haspopup="true"
                    onClick={handleAccountMenuOpen}
                    color="inherit"
                >
                    <PersonOutlineIcon />
                </IconButton>
            )}
            {accountMenu}
            <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} setRegisterOpen={handleRegisterModalOpen} openForgotPasswordModal={handleForgotPasswordModalOpen} />
            <RegisterModal open={registerModalOpen} setOpen={setRegisterModalOpen} openLoginModal={handleLoginModalOpen} />
            <ForgotPasswordModal open={forgotPasswordModalOpen} setOpen={setForgotPasswordModalOpen} openLoginModal={handleLoginModalOpen} />
        </Box>
    );
};

const MobileMenu = () => {
    const classes = useStyles();
    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileAnchorEl);
    const handleMobileMenuOpen = (event) => {
        setMobileAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileAnchorEl(null);
    };

    const mobileMenuId = 'mobile-menu';
    const mobileMenu = (
        <Menu
            anchorEl={mobileAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem key="Easy Builder" component={Link} to="/easybuilder" className={classes.subMenuItem} onClick={handleMobileMenuClose}>
                Easy builder
            </MenuItem>
            <MenuItem key="Pro Builder" component={Link} to="/probuilder" className={classes.subMenuItem} onClick={handleMobileMenuClose}>
                Pro builder
            </MenuItem>
            <MenuItem key="Productos" component={Link} to="/categorias" className={classes.subMenuItem} onClick={handleMobileMenuClose}>
                Productos
            </MenuItem>
            <MenuItem key="Builds" component={Link} to="/productos/pcs-escritorio-gaming" className={classes.subMenuItem} onClick={handleMobileMenuClose}>
                Builds
            </MenuItem>
            <MenuItem className={classes.subMenuItem} component={Link} key="Ofertas" onClick={handleMobileMenuClose} to="/ofertas">
                Ofertas 🔥
            </MenuItem>
            {/* <MenuItem
                key="Comunidad GF"
                component={Link}
                to={{ pathname: process.env.REACT_APP_COMUNIDAD_URL }}
                target="_blank"
                className={classes.subMenuItem}
                onClick={handleMobileMenuClose}
            >
                Comunidad GF
            </MenuItem> */}
        </Menu>
    );

    return (
        <Box>
            <IconButton
                edge="end"
                className={classes.sectionMobile}
                aria-label="open drawer"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
                onClick={handleMobileMenuOpen}
            >
                <MenuIcon />
            </IconButton>
            {mobileMenu}
        </Box>
    );
};

export default function GDHeader() {
    const classes = useStyles();
    const [userState] = useContext(UserContext);
    const [accountAnchorEl, setAccountAnchorEl] = useState(false);
    const location = useLocation();

    const handleAccountMenuOpen = (event) => {
        setAccountAnchorEl(event.target);
    };

    const handleAccountMenuClose = () => {
        setAccountAnchorEl(null);
    };

    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Container maxWidth="xl" className={classes.appbarContainer}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/" alt="Home">
                        <Typography variant={LOCATIONS_REQUIRING_H1.includes(location.pathname) ? 'h1' : 'h2'} style={{ height: 34 }}>
                            <Hidden mdDown>
                                <img src={logo} alt="Gamer Factory" className={classes.logo} />
                            </Hidden>
                            <Hidden lgUp>
                                <img src={logoMobile} alt="Gamer Factory" className={classes.logo} />
                            </Hidden>
                        </Typography>
                    </Link>
                    <Box flexGrow={1}>
                        <DesktopMenuLinks />
                    </Box>
                    <Box flexGrow={1}>
                        <GDSearcher />
                    </Box>
                    <Box display="flex">
                        <GDCart totalCartItems={cartHelper.calculateTotalItems(userState.user?.cart?.items || [])} />
                        <AccountMenu
                            accountAnchorEl={accountAnchorEl}
                            setAccountAnchorEl={setAccountAnchorEl}
                            handleAccountMenuOpen={handleAccountMenuOpen}
                            handleAccountMenuClose={handleAccountMenuClose}
                            userImage={userState.user?.profileImageURL}
                        />
                        <MobileMenu />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
