import { AxiosResponse } from 'axios';

import { SHIPPING_TYPES } from '../common/constants';
import { Cart, CartWithRequiredId } from '../types/Cart';
import { InstallmentWithoutMPInterest } from '../types/InstallmentWithoutMPInterest';
import { Product } from '../types/Product';
import GDAxios from './axios';
import {
    ApplyCouponParams,
    CreateOrderParams,
    DataAndTotal,
    DeleteCouponParams,
    EasyBuilderFindProductsParams,
    ForgotPasswordParams,
    GetCompatibleProductsParams,
    GetProductFiltersParams,
    GetProductParams,
    LoginParams,
    ProductFiltersResponse,
    RecoverPasswordParams,
    RegisterParams,
    SearchProductsParams,
    ShippingQuoteParams,
} from './types';

const encodeJsonForUrl = (json: object | string) => encodeURIComponent(JSON.stringify(json));

const auth = {
    login: ({ email, password, rememberMe }: LoginParams) => GDAxios.post('/auth/login', { email, password, rememberMe }),
    register: ({ email, password, name }: RegisterParams) => GDAxios.post('/auth/register', { email, password, name }),
    forgotPassword: ({ email }: ForgotPasswordParams) => GDAxios.post('/auth/forgotPassword', { email }),
    recoverPassword: ({ token, newPassword }: RecoverPasswordParams) => GDAxios.post('/auth/recoverPassword', { token, newPassword }),
    logout: () => GDAxios.post('/auth/logout'),
};

const productsRoot = '/products';
const cartsRoot = '/carts';
const ordersRoot = '/orders';
const configsRoot = '/configs';

const products = {
    get: ({ slug }: GetProductParams) => GDAxios.get(`${productsRoot}/front`, { params: { slug } }),
    compatibles: ({ requestedTypeList, brand, build: [...buildRest], additionalSpecsFilter, filter, page, perPage, sort }: GetCompatibleProductsParams) =>
        GDAxios.post(
            `${productsRoot}/compatibles`,
            {
                requestedTypeList,
                brand,
                build: [...buildRest],
                additionalSpecsFilter,
            },
            {
                params: {
                    filter: filter || '{}',
                    page,
                    perPage,
                    sort: sort || '{"field":"name","order":"ASC"}',
                },
            }
        ),
    getOperativeSystems: () => GDAxios.get(`${productsRoot}/operative-systems`),
    predict: (search: string) => {
        const query = `query=${encodeJsonForUrl(search)}`;

        return GDAxios.get(`${productsRoot}/predict?${query}`);
    },
    search: ({ type, filter = {}, page, perPage, sort, tag }: SearchProductsParams): Promise<AxiosResponse<DataAndTotal<Product>>> => {
        let query = '';

        const filterWithType = type ? { ...filter, type } : filter;

        query += `filter=${encodeJsonForUrl(filterWithType)}`;

        const pageToUse = page ?? 1;
        query += `&page=${pageToUse.toString()}`;

        const perPageToUse = perPage ?? 10;
        query += `&perPage=${perPageToUse.toString()}`;

        const sortToUse = sort ?? {};
        query += `&sort=${encodeJsonForUrl(sortToUse)}`;
        if (tag) query += `&tag=${tag}`;

        return GDAxios.get<DataAndTotal<Product>>(`${productsRoot}/search?${query}`);
    },
    easyBuilderFind: ({ cpuBrand, games, budget }: EasyBuilderFindProductsParams) =>
        GDAxios.get(`${productsRoot}/easyBuilderFind`, {
            params: {
                cpuBrand,
                games,
                budget,
            },
        }),
    filters: ({ type }: GetProductFiltersParams) =>
        GDAxios.get<ProductFiltersResponse>(`${productsRoot}/filters`, {
            params: {
                type,
            },
        }),
};

const builds = {
    easyBuilderFind: (filter: EasyBuilderFindProductsParams) => products.easyBuilderFind(filter),
};

const games = {
    getAll: () => GDAxios.get('/games?perPage=999'),
};

const users = {
    me: () => GDAxios.get('auth/me'),
    patchMe: (updatedData: unknown) => GDAxios.patch('/users', updatedData),
};

const carts = {
    get: (id: string) => GDAxios.get<Cart>(`${cartsRoot}/${id}`),
    post: () => GDAxios.post<Cart>(cartsRoot),
    updateCart: (cart: CartWithRequiredId, isListPrice?: boolean) => GDAxios.put<Cart>(`${cartsRoot}/${cart.id}`, { cart, isListPrice }),
    applyCoupon: ({ id, code }: ApplyCouponParams) => GDAxios.post<Cart>(`${cartsRoot}/${id}/coupon`, { coupon: code }),
    deleteCoupon: ({ id }: DeleteCouponParams) => GDAxios.delete<Cart>(`${cartsRoot}/${id}/coupon`),
    getAvailableInstallmentOptions: ({ id, shippingCost }: { id: string; shippingCost: number }) => {
        let url = `${cartsRoot}/${id}/installments`;
        if (shippingCost) url += `?shippingCost=${String(shippingCost)}`;
        return GDAxios.get<InstallmentWithoutMPInterest[]>(url);
    },
};

const shipping = {
    quote: ({ productId, cartId, shippingData }: ShippingQuoteParams) =>
        GDAxios.post('/shipping/quote', {
            productId,
            cartId,
            shippingData: {
                ...shippingData,
                codigoPostal: shippingData.locality.codigoPostal,
            },
        }),
    destinations: () => GDAxios.get('/shipping/destinations'),
    agencies: () => GDAxios.get('/shipping/agencies'),
};

const orders = {
    get: () => GDAxios.get('/users/me/orders'),
    post: ({ buyerData, cartId, paymentData, shippingData: { address, dni, firstName, floor, lastName, locality, phone, price, province, shippingType, agencyId } }: CreateOrderParams) =>
        GDAxios.post(`${ordersRoot}/from-cart/${cartId}`, {
            buyerData: {
                ...buyerData,
                ...(buyerData.locality && {
                    locality: buyerData.locality.name,
                    codigoPostal: buyerData.locality.codigoPostal,
                }),
                province: buyerData.province?.name ?? '',
            },
            paymentData,
            shippingData: {
                address,
                dni,
                firstName,
                floor,
                lastName,
                phone,
                price,
                shippingType,
                useTelegramForTracking: false,
                agencyId,
                ...(shippingType !== SHIPPING_TYPES.pickupAtWarehouse && {
                    locality: locality.name,
                    codigoPostal: locality.codigoPostal,
                    province: province.name,
                }),
            },
        }),
};

const slidesSort = JSON.stringify({ field: 'order', order: 'ASC' });
const slides = {
    getAll: () => GDAxios.get('/slides?perPage=999&sort=' + slidesSort),
};

const brandImages = {
    getAll: () => GDAxios.get('/brandImages?perPage=999'),
};

const configs = {
    getAll: () => GDAxios.get(`${configsRoot}/front`),
};

export default {
    auth,
    brandImages,
    builds,
    carts,
    games,
    orders,
    products,
    shipping,
    slides,
    users,
    configs,
};
