import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useContext, useState } from 'react';
import * as React from 'react';

import { UserContext, useUser } from '../../../contexts/User';
import cartHelper from '../../../helpers/cartHelper';
import { sendCartItemQuantityModificationGAEvent, sendRemoveFromCartGAEvent } from '../../../helpers/gaHelper';
import imageHelper from '../../../helpers/imageHelper';
import useSnackbarGD from '../../../hooks/useSnackbar';
import ProductQuantitySelection from '../../product/ProductQuantitySelection';
import DialogProductDeleteConfirmation from './DialogProductDeleteConfirmation';

const useStyles = makeStyles(() => ({
    fontName: {
        fontWeight: 400,
        fontSize: '12px',
        paddingLeft: '6px',
    },
    fontEliminar: {
        fontWeight: 700,
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    fontPrecio: {
        fontWeight: 700,
        fontSize: '16px',
    },
    gridContainer: {
        justifyContent: 'flex-end',
    },
    itemImage: {
        maxWidth: '58px',
        maxHeight: '58px',
    },
    iconEliminar: {
        color: '#EA4335',
        fontSize: '15px',
        paddingRight: 3,
    },
    iconButton: {
        paddingLeft: 0,
    },
}));

const DropdownCartItem = ({ item, index, setLoading, loading }) => {
    const classes = useStyles();
    const [state, dispatch] = useContext(UserContext);
    const [quantity, setQuantity] = useState(item.quantity ?? 1);
    const { showSnackbarMessage } = useSnackbarGD();
    const {
        cart: { updateCart },
    } = useUser();

    const handleUpdateCart = (updatedCart) => {
        const cartToSend = updatedCart;
        cartToSend.items = cartHelper.parseProductsToSend(cartToSend.items);
        setLoading(true);
        updateCart(cartToSend)
            .then(() => {
                showSnackbarMessage('Se actualizó la cantidad del producto.', 'success');
            })
            .finally(() => setLoading(false));
    };

    const handleChangeQuantity = async (valueToAdd) => {
        let newQuantity = valueToAdd + quantity;
        newQuantity = newQuantity < 0 ? 0 : newQuantity;
        const cartAux = state.user.cart;
        const itemIndex = cartAux.items.findIndex((itemCart) => itemCart.id === item.id);
        const itemAux = cartAux.items[itemIndex];
        if (newQuantity > 0 && newQuantity <= itemAux.stock) {
            const oldQuantity = itemAux.quantity;
            itemAux.quantity = newQuantity;
            handleUpdateCart(cartAux);
            setQuantity(newQuantity);
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price.special,
                },
                oldQuantity,
                newQuantity,
            });
        }
        if (newQuantity > item.stock) {
            setQuantity(item.stock);
            showSnackbarMessage(`Disculpa, solo tenemos ${item.stock} unidad/es en stock`, 'warning');
        }
    };

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [itemIdToDelete, setItemIdToDelete] = React.useState(-1);
    const [buildIdToDelete, setBuildIdToDelete] = useState(null);

    const deleteItem = (itemId, buildId = null) => {
        const newCart = state.user.cart;
        let itemsToSend;
        let deletedItem;
        if (buildId) {
            dispatch({
                type: 'DELETE_BUILD',
            });
            itemsToSend = newCart.items.filter((product) => product.buildId !== buildId);
            // FIXME: We're missing to remove the SO associated to the build from the cart
            [deletedItem] = newCart.items.find((product) => product.buildId === buildId);
        } else {
            const indexToDelete = newCart.items.findIndex((product) => product.id === itemId);
            [deletedItem] = newCart.items.splice(indexToDelete, 1);
            itemsToSend = newCart.items;
        }
        sendRemoveFromCartGAEvent([
            {
                item_id: deletedItem.id,
                item_name: deletedItem.name,
                item_category: deletedItem.type,
                price: deletedItem.price.special,
                quantity: deletedItem.quantity,
            },
        ]);
        newCart.items = cartHelper.parseProductsToSend(itemsToSend);
        updateCart(newCart).then(() => {
            showSnackbarMessage('Producto eliminado del carrito.', 'success');
        });
    };

    const handleClickOpenDeleteDialog = (itemId, buildId) => {
        setItemIdToDelete(itemId);
        setBuildIdToDelete(buildId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = () => {
        deleteItem(itemIdToDelete, buildIdToDelete);
        handleCloseDeleteDialog();
    };

    const isLastItem = state.user.cart.length === index + 1;
    return (
        <>
            <Box mt={2} mb={2} px={3}>
                <Grid container>
                    <Grid item xs={3}>
                        {loading ? (
                            <CircularProgress size={36} />
                        ) : (
                            <img src={imageHelper.getProductImageSrc(item)} alt={imageHelper.getProductImageAlt(item)} className={classes.itemImage} />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h6" className={classes.fontName}>
                            {item.name?.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <Button className={classes.iconButton} onClick={() => handleClickOpenDeleteDialog(item.id, item.buildId)}>
                            <HighlightOffOutlinedIcon className={classes.iconEliminar} />
                            <Typography variant="h6" className={classes.fontEliminar}>
                                Eliminar
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <ProductQuantitySelection
                            onQuantityChanged={handleChangeQuantity}
                            selectedQuantity={quantity}
                            maxQuantity={item.stock}
                            disabled={item.buildId || item.type === 'build'}
                        />
                    </Grid>
                    <Grid item container xs={4} className={classes.gridContainer}>
                        <Grid item>
                            {loading ? (
                                <CircularProgress size={18} />
                            ) : (
                                <Typography variant="h6" className={classes.fontPrecio}>
                                    {item.price?.special ? cartHelper.formatPrice(item.price.special) : <CircularProgress />}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {isLastItem ? null : <Divider />}

            <DialogProductDeleteConfirmation openDeleteDialog={openDeleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} handleDeleteItem={handleDeleteItem} />
        </>
    );
};

export default DropdownCartItem;
