import _ from 'lodash';
import { Dispatch, PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';

import API from '../../api/API';
import cartHelper from '../../helpers/cartHelper';
import { sendAddToCartGAEvent, sendCartItemQuantityModificationGAEvent, sendRemoveFromCartGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import storageHelper from '../../helpers/storageHelper';
import { CartProductTypes } from '../../helpers/types';
import useSnackbarGD, { SelectedVariantType } from '../../hooks/useSnackbar';
import { Build } from '../../types/Build';
import { Cart, CartItem, CartWithRequiredId } from '../../types/Cart';
import { Product } from '../../types/Product';
import { ShippingData } from '../../types/ShippingData';
import { USER_ACTIONS } from './constants';
import { userInitialState, userReducer } from './reducer';
import { UserActions, UserState } from './types';

export const UserContext = createContext<[UserState, Dispatch<UserActions>]>([userInitialState, () => null]);

export const UserProvider = ({ children }: PropsWithChildren<ReactNode>) => {
    const [state, dispatch] = useReducer(userReducer, userInitialState);

    return <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>;
};

const triggerAPICartUpdate = _.debounce(
    async ({ cart, dispatch, showSnackbarMessage }: { cart: Cart; dispatch: Dispatch<UserActions>; showSnackbarMessage: (message: string, variant: SelectedVariantType) => void }) => {
        const cartToSend: Cart = {
            ...cart,
            items: cartHelper.parseProductsToSend(cart.items) as CartItem[],
        };
        if (!cartToSend.id) {
            const response = await API.carts.post();
            cartToSend.id = response.data.id;
        }

        return API.carts
            .updateCart(cartToSend as CartWithRequiredId)
            .then((response) => {
                dispatch({ type: USER_ACTIONS.UPDATE_CART, cart: response.data });
            })
            .catch(async (error: unknown) => {
                dispatch({ type: USER_ACTIONS.UPDATE_CART, cart });
                await handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                    customErrorMessage: 'Ups! Tuvimos un problema y no pudimos sincronizar tu carrito. No se guardaron los últimos cambios. Probá nuevamente en unos minutos',
                });
            });
    },
    2000
);

export const useUser = () => {
    const context = useContext(UserContext);
    const [state, dispatch] = context;
    const currentItems = [...(state.user.cart?.items ?? [])];
    const { showSnackbarMessage } = useSnackbarGD();

    useEffect(() => () => void triggerAPICartUpdate.flush(), []);

    const updateCart = async (newCart: Cart) => {
        dispatch({ type: USER_ACTIONS.UPDATE_CART, cart: newCart });
        await triggerAPICartUpdate({ cart: newCart, dispatch, showSnackbarMessage });
    };

    const handleLogout = async (redirectToHome = true) => {
        storageHelper.clearStorage();
        await API.auth.logout();
        dispatch({ type: USER_ACTIONS.LOGOUT });
        if (redirectToHome) {
            window.location.href = '/';
        }
    };

    const removeProductFromCart = async (product: Product) => {
        const newItems = [...currentItems];
        const removedItems = _.remove(newItems, (i) => i.id === product.id);
        const newCart = {
            ...state.user.cart,
            items: newItems,
        };
        sendRemoveFromCartGAEvent(cartHelper.formatCartItemsForGAEvent(removedItems, true));
        await updateCart(newCart);
    };

    const getBuildQuantity = (build: Build) => {
        let quantity = 0;
        state.user.cart?.items.forEach((item) => {
            if (!item.buildId && item.id === build.id) quantity += 1;
        });
        return quantity;
    };
    const getItemInCartForProduct = (p: Product) => state.user.cart?.items.find((item) => !item.buildId && item.id === p.id);

    const findItemInCartItems = (item: CartItem, cartItems: CartItem[]) => {
        const existingItemIndexOnCart = cartItems.findIndex((i) => !i.buildId && i.id === item.id);
        const isBuild = item.cartProductType === CartProductTypes.BUILD;
        return { existingItemIndexOnCart, isBuild };
    };

    const removeAssociatedBuildSOFromCartIfExists = (buildIndexToRemove: number, cartItems: CartItem[]) => {
        const cartItemsCopy = [...cartItems];
        const buildItem = cartItems[buildIndexToRemove];
        if (!buildItem) {
            throw new Error('Build item not found in cart items');
        }
        const { buildSubItemIndex } = buildItem;
        const SOIndexToRemove = cartItemsCopy.findIndex((item) => item.buildId === buildSubItemIndex);
        const so = SOIndexToRemove !== -1 ? cartItemsCopy.splice(SOIndexToRemove, 1)[0] : null;
        return { so, cartItemsWithoutSO: cartItemsCopy };
    };

    const updateItemOnCart = async (item: CartItem) => {
        let cartItems = [...currentItems];
        const { existingItemIndexOnCart, isBuild } = findItemInCartItems(item, cartItems);
        const doesItemExistOnCart = existingItemIndexOnCart !== -1;

        if (isBuild && doesItemExistOnCart && !!item.quantity) {
            // Reducing build quantity, we need to remove an instance of the build
            if (!state.user.cart?.items) {
                throw new Error('Cart items not found');
            }
            const { so, cartItemsWithoutSO } = removeAssociatedBuildSOFromCartIfExists(existingItemIndexOnCart, state.user.cart.items);
            cartItems = cartItemsWithoutSO;
            const buildRemoved = cartItems.splice(existingItemIndexOnCart, 1)[0];
            const itemsRemoved = [buildRemoved];
            if (so) itemsRemoved.push(so);
            sendRemoveFromCartGAEvent(cartHelper.formatCartItemsForGAEvent(itemsRemoved, true));
        } else if (isBuild) {
            // Increasing build quantity, we need to add an instance of the build
            cartItems.push({
                ...item,
                type: item.cartProductType,
                quantity: 1,
                buildSubItemIndex: crypto.randomUUID(),
            });
            sendAddToCartGAEvent([
                {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                    quantity: 1,
                },
            ]);
        } else if (doesItemExistOnCart) {
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                },
                oldQuantity: cartItems[existingItemIndexOnCart]?.quantity,
                newQuantity: item.quantity,
            });
            if (cartItems[existingItemIndexOnCart]) cartItems[existingItemIndexOnCart].quantity = item.quantity;
        } else {
            cartItems.push({ ...item, type: item.cartProductType });
            sendAddToCartGAEvent([
                {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                    quantity: item.quantity,
                },
            ]);
        }

        await updateCart({ ...state.user.cart, items: cartItems });
    };

    // FIXME: This is almost the same as updateItemOnCart, refactor
    const addItemOnCart = async (item: CartItem) => {
        const cartItems = [...currentItems];
        const { existingItemIndexOnCart, isBuild } = findItemInCartItems(item, cartItems);

        const oldQuantity = existingItemIndexOnCart !== -1 ? cartItems[existingItemIndexOnCart]?.quantity ?? 0 : 0;

        if (isBuild) {
            // Adding a build, we need to add item.quantity instances of the build
            for (let i = 0; i < item.quantity; i += 1) {
                cartItems.push({
                    ...item,
                    type: item.cartProductType,
                    quantity: 1,
                    buildSubItemIndex: crypto.randomUUID(),
                });
            }
            sendAddToCartGAEvent([
                {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                    quantity: item.quantity,
                },
            ]);
        } else if (cartItems[existingItemIndexOnCart]) {
            cartItems[existingItemIndexOnCart].quantity = item.quantity;
            sendCartItemQuantityModificationGAEvent({
                item: {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                },
                oldQuantity,
                newQuantity: item.quantity,
            });
        } else {
            cartItems.push({ ...item, type: item.cartProductType });
            sendAddToCartGAEvent([
                {
                    item_id: item.id,
                    item_name: item.name,
                    item_category: item.type,
                    price: item.price,
                    quantity: item.quantity,
                },
            ]);
        }
        await updateCart({ ...state.user.cart, items: cartItems });
    };

    const hasProduct = (p: Product) => Boolean(getItemInCartForProduct(p));
    const clear = () => {
        sendRemoveFromCartGAEvent(
            state.user.cart?.items.map((item) => {
                let result: Record<string, string | number> = cartHelper.formatCartItemForGAEvent(item, true);
                if (item.buildId) {
                    result = { ...result, for_probuild_id: item.buildId };
                }
                return result;
            })
        );
        const clearedCart = { ...state.user.cart, items: state.user.cart?.items ?? [] };
        clearedCart.items = [];
        if (!clearedCart.id) {
            return;
        }
        return API.carts
            .updateCart(clearedCart as CartWithRequiredId)
            .then((response) => {
                dispatch({ type: USER_ACTIONS.UPDATE_CART, cart: response.data });
                showSnackbarMessage('Se ha limpiado el carrito.', 'success');
            })
            .catch(async (error: unknown) => {
                await handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                    customErrorMessage: 'Hubo un problema al limpiar el carrito. Por favor intente nuevamente en unos minutos',
                });
                throw error;
            });
    };

    const updateShippingData = (shippingData: ShippingData) => {
        const newUser = { ...state.user, shippingData };
        dispatch({ type: USER_ACTIONS.SET_USER, user: newUser });
    };
    return {
        state,
        dispatch,
        handleLogout,
        cart: {
            addItemOnCart,
            updateItemOnCart,
            getBuildQuantity,
            removeProductFromCart,
            updateCart,
            getItemInCartForProduct,
            hasProduct,
            clear,
        },
        shippingData: {
            updateShippingData,
        },
    };
};
