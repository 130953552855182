export const initialOrder = {
    buildIds: [],
    builds: [],
    buyer: null,
    buyerData: {},
    dates: {},
    id: null,
    productIds: [],
    products: [],
    shippingData: {},
};
