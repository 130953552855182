import axios from 'axios';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

import API from '../api/API';
import { USER_ACTIONS } from '../contexts/User/constants';
import { UserActions } from '../contexts/User/types';
import { SelectedVariantType } from '../hooks/useSnackbar';

const logout = (userContextDispatch: Dispatch<UserActions>) => {
    void API.auth.logout();
    userContextDispatch({ type: USER_ACTIONS.LOGOUT });
};

interface HandleErrorParams {
    customErrorMessage?: string;
    showSnackbarMessage: (message: string, variant: SelectedVariantType) => void;

    //El error puede tomar otras formas si no me equivoco (x eso unknown)
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    error?: unknown;
    navigate?: NavigateFunction;
    redirectIfNotFound?: boolean;
    redirectToHome?: boolean;
    userContextDispatch: Dispatch<UserActions>;
}

const handleError = ({ customErrorMessage, showSnackbarMessage, error, navigate, redirectIfNotFound = false, redirectToHome, userContextDispatch }: HandleErrorParams) => {
    let message = '';
    const isAxiosError = axios.isAxiosError(error);
    if (isAxiosError && error.response?.status === 401) {
        showSnackbarMessage('Su sesión ha caducado. Vuelva a iniciar sesión.', 'warning');
        logout(userContextDispatch);
        if (redirectToHome) navigate?.('/');
    } else if (isAxiosError && error.response?.status === 404 && redirectIfNotFound) {
        navigate?.('/not-found', { replace: true });
    } else {
        message = 'Network error';
        if (customErrorMessage) message = customErrorMessage;
        showSnackbarMessage(message, 'error');
    }

    console.error(error);

    // Ignoro la advertencia porque no estoy seguro si haría falta algún cambio y no tengo tiempo de investigarlo.
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    return Promise.reject(message);
};

const handler = {
    logout,
    handleError,
};

export default handler;
